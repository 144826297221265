/*jshint esversion: 6 */

import global from '../../global.js';
import * as fs from './fairshare.js';
import * as db from './data.js';
import * as fairshare_soil from './fairshare_soil.js';
import * as fairshare_explorer from './fairshare_explorer.js';
import * as fairshare_oo from './fairshare_oo.js';
import * as fs_chat from './fs_chat.js';
import * as fs_trap from './fs_trap.js';
import * as fs_report from './fairshare_report.js';
import * as report_management from './report_management.js';
import * as fs_zoo from './fairshare_zoo.js';
import * as fs_warehouse from './fs_warehouse.js';
import * as fs_marketing from './fs_marketing.js';
import * as bluetooth_new from '../../core/ble.js';
import * as m21 from './misura21.js';
import * as ws from './ws.js';
import * as feed from './feed.js';
import * as copilot from './copilot.js';

// import  * as model from '.fairshare_model.js';

function cleanPage(){
  var html='<a href="javascript:history.back()" class="link" data-panel="left"><i class="f7-icons">chevron_left</i><span class="if-not-md">'+('Back')+'</span></a>';
  jQuery('.left').html(html);

  jQuery('#ae_navbar_right').html('');

  jQuery('#download_app').hide();

  jQuery('#footer').parent().show();
  jQuery('#footer').html('');

  console.log("Nascondo...");

  var height=35;
  if(global.iOS()){
    height=25;
  }
  jQuery('.navbar .title').html('<img src="'+global.icon+'" height="'+height+'" />');

  jQuery('.custom_farm_menu').hide();
  jQuery('.custom_link_access').parent().hide();
  jQuery('.custom_link_chat').parent().hide();
  jQuery('.custom_link_farm_edit').parent().hide();
  jQuery('.custom_link_farm_bt').parent().hide();
  jQuery('.custom_link_soil').parent().hide();
  jQuery('.custom_link_warehouse').parent().hide();
  jQuery('.custom_link_marketing').parent().hide();
  jQuery('.custom_link_ws').parent().hide();

}

export default function exe_routes(router){



  router.on({
    'farm/:id_farm_center/bluetooth_new':function(param){
      jQuery('#footer').parent().show();
      jQuery('#footer').html('');

      bluetooth_new.bluetooth(param.id_farm_center);
    },
    'start': function () {
      fs.start();
    },
    'reports': function () {
      cleanPage();
      report_management.download_report();
    },
    'copilot': function () {
      cleanPage();

      copilot.vocal_message();
    },
    'misura21': function () {
      cleanPage();

      var html='<a href="#" class="link icon-only panel-open" data-panel="left"><i class="f7-icons">line_horizontal_3</i></a>';
      jQuery('.left').html(html);

      m21.misura21();
    },
    'misura21/event/:id_calendar': function (param) {
      cleanPage();

      m21.event(param.id_calendar);
    },
    'grant_access/:id_access': function (param) {
      // jQuery('.custom_link_access').parent().hide();
      // jQuery('.custom_link_farm_edit').parent().hide();
      //
      // var html='<a href="#" class="link icon-only panel-open" data-panel="left"><i class="f7-icons">line_horizontal_3</i></a>';
      // jQuery('.left').html(html);
      fs.grantAccess(param.id_access);
      // fs.farms();
    },
    'farms': function () {
      // jQuery('.custom_link_access').parent().hide();
      // jQuery('.custom_link_chat').parent().hide();
      // jQuery('.custom_link_farm_edit').parent().hide();
      // jQuery('.custom_link_soil').parent().hide();
      cleanPage();

      var html='<a href="#" class="link icon-only panel-open" data-panel="left"><i class="f7-icons">line_horizontal_3</i></a>';
      jQuery('.left').html(html);

      fs.farms();
    },
    'advice': function (param) {
      cleanPage();
      fs_chat.advice_home();
    },
    'advice/new': function (param) {
      cleanPage();
      fs_chat.advice_new();
    },
    'credits': function (param) {
      cleanPage();
      fs.credits();
    },
    'feed': function (param) {
      cleanPage();
      feed.feed_home();
    },
    'detail/:id_farm_center/:label': function (param) {
      cleanPage();

      var variables;
      jQuery.each(global.tls,function(k,v){
        if (v.label==param.label) {
          variables=v.variables;
        }
      });


      fs_zoo.detail(param.label,variables, param.id_farm_center);
    },
    'create_farm_center/:who': function (param) {
      cleanPage();

      console.log("Sei "+param.who);
      fs.createFarmCenter(param.who);
    },
    'farm/:id_farm_center': function (param) {
      cleanPage();

      jQuery('#ae_navbar_right').html('<i class="f7-icons">pencil</i>');
      jQuery('#ae_navbar_right i').click(function(){
        location.hash=`farm/${param.id_farm_center}/edit/base`;
      });
      // jQuery('.custom_link_access').parent().show();
      // jQuery('.custom_link_chat').parent().show();
      // jQuery('.custom_link_farm_edit').parent().show();
      // jQuery('.custom_link_soil').parent().show();


      console.log("Dettaglio della farm "+param.id_farm_center);
      fs.show_farm(param.id_farm_center);
    },
    'farm/:id_farm_center/season/:id_season': function (param) {
      cleanPage();

      // jQuery('.custom_link_access').parent().show();
      // jQuery('.custom_link_chat').parent().show();
      // jQuery('.custom_link_farm_edit').parent().show();
      // jQuery('.custom_link_soil').parent().show();

      console.log("Dettaglio della farm "+param.id_farm_center+" Stagione "+param.id_season);
      fs.show_farm(param.id_farm_center, param.id_season);
    },
    'farm/:id_farm_center/season/:id_season/operation/': function (param) {
      cleanPage();

      fs_report.home_operation(param.id_farm_center, param.id_season);
    },
    'farm/:id_farm_center/season/:id_season/observation/': function (param) {
      cleanPage();

      fs_report.home_observation(param.id_farm_center, param.id_season);
    },
    'farm/:id_farm_center/season/:id_season/trap/': function (param) {
      cleanPage();

      fs_trap.home_trap(param.id_farm_center, param.id_season);
    },
    'farm/:id_farm_center/season/:id_season/trap/add_trap': function (param) {
      cleanPage();

      fs_trap.add_trap(param.id_farm_center, param.id_season);
    },
    'farm/:id_farm_center/season/:id_season/trap/:id_trap': function (param) {
      cleanPage();

      fs_trap.edit_trap(param.id_farm_center, param.id_season, param.id_trap);
    },
    'farm/:id_farm_center/edit/:tab': function (param) {
      cleanPage();

      //TODO facciamo due volta la richiesta dei dati sia adesso che fuori; va messo tutto su edit_farm
      var fc=db.getFarmData(param.id_farm_center);

      if (!fc.field || fc.field.length==0) {
        jQuery('#ae_navbar_right').html('<i class="f7-icons">trash</i>');
        jQuery('#ae_navbar_right i').click(function(){
          db.deleteFarm(param.id_farm_center);
          location.hash='farms';
          location.reload();
        });
      }


      console.log("Modifica farm "+param.id_farm_center);
      fs.edit_farm(param.id_farm_center, param.tab);
    },    
    'farm/:id_farm_center/new_season': function (param) {
      cleanPage();

      console.log("Modifica farm "+param.id_farm_center);
      fs.new_season(param.id_farm_center);
    },
    'farm/:id_farm_center/new_soil': function (param) {
      cleanPage();

      console.log("Modifica farm "+param.id_farm_center);
      fairshare_soil.new_soil(param.id_farm_center);
    },
    'farm/:id_farm_center/soil': function (param) {
      // cleanPage();
      var html=`<a onclick='location.hash="#farm/${param.id_farm_center}"' class="link" data-panel="left"><i class="f7-icons">chevron_left</i><span class="if-not-md">${('Back')}</span></a>`;
      jQuery('.left').html(html);

      console.log("Mhome dei suoli "+param.id_farm_center);
      fairshare_soil.show_soil(param.id_farm_center);
    },
    'farm/:id_farm_center/soil/:id_soil': function (param) {
      cleanPage();

      console.log("Modifica soil "+param.id_farm_center);
      fairshare_soil.edit_soil(param.id_farm_center, param.id_soil);
    },
    'farm/:id_farm_center/access': function (param) {

      // cleanPage();
      var html=`<a onclick='location.hash="#farm/${param.id_farm_center}"' class="link" data-panel="left"><i class="f7-icons">chevron_left</i><span class="if-not-md">${('Back')}</span></a>`;
      jQuery('.left').html(html);


      fs.access_farm(param.id_farm_center);
    },
    'farm/:id_farm_center/add_field': function (param) {
      cleanPage();

      fs.createPlot(param.id_farm_center);


      //fs.upsert_field(param.id_farm_center);
    },
    'farm/:id_farm_center/ws': function (param) {
      cleanPage();

      var html='<a href="#" class="link icon-only panel-open" data-panel="left"><i class="f7-icons">line_horizontal_3</i></a>';
      jQuery('.left').html(html);
      
      jQuery('#ae_navbar_right').html('<i class="f7-icons">plus</i>');
      // jQuery('.title').css('left','0');
      jQuery('#ae_navbar_right i').click(function(){
        location.hash=`farm/${param.id_farm_center}/ws/new`;
      });


      ws.ws_map(param.id_farm_center);
    },
    'farm/:id_farm_center/ws/new': function (param) {
      cleanPage();
      
      ws.addStation(param.id_farm_center);
    },
    'chat':function(){
      cleanPage();

      var html='<a href="#" class="link icon-only panel-open" data-panel="left"><i class="f7-icons">line_horizontal_3</i></a>';
      jQuery('.left').html(html);

      fs_chat.allChats();
    },
    'chat/:id_group': function (param) {
      cleanPage();

      var html=`<a onclick='location.hash="#chat"' class="link" data-panel="left"><i class="f7-icons">chevron_left</i><span class="if-not-md">${('Back')}</span></a>`;
      jQuery('.left').html(html);

      fs_chat.home(param.id_group);
    },
    'farm/:id_farm_center/plot/:id_plot': function (param) {
      // cleanPage();
      var html=`<a onclick='location.hash="#farm/${param.id_farm_center}"' class="link" data-panel="left"><i class="f7-icons">chevron_left</i><span class="if-not-md">${('Back')}</span></a>`;
      jQuery('.left').html(html);

      fs.show_plot(param.id_farm_center, param.id_plot);
    },
    'farm/:id_farm_center/plot/:id_plot/edit': function (param) {
      cleanPage();

      fs.edit_plot(param.id_farm_center, param.id_plot);
    },
    'farm/:id_farm_center/field/:id_field': function (param) {
      jQuery('#ae_navbar_right').html('<i class="f7-icons">pencil</i>');
      jQuery('#ae_navbar_right i').click(function(){
        location.hash=`farm/${param.id_farm_center}/field/${param.id_field}/edit`;
      });

      // cleanPage();
      var html=`<a onclick='location.hash="#farm/${param.id_farm_center}"' class="link" data-panel="left"><i class="f7-icons">chevron_left</i><span class="if-not-md">${('Back')}</span></a>`;
      jQuery('.left').html(html);

      fs.show_field(param.id_farm_center, param.id_field);
    },
    'farm/:id_farm_center/field/:id_field/model/:run_model': function (param) {
      // cleanPage();
      var html=`<a onclick='location.hash="#farm/${param.id_farm_center}"' class="link" data-panel="left"><i class="f7-icons">chevron_left</i><span class="if-not-md">${('Back')}</span></a>`;
      jQuery('.left').html(html);

      fs.show_field(param.id_farm_center, param.id_field, param.run_model);
    },
    // 'farm/:id_farm_center/field/:id_field/model/:model_name/chart': function (param) {
    //   // cleanPage();
    //   // var html=`<a onclick='location.hash="#farm/${param.id_farm_center}"' class="link" data-panel="left"><i class="f7-icons">chevron_left</i><span class="if-not-md">${('Back')}</span></a>`;
    //   // jQuery('.left').html(html);
    //
    //   model.modelChart(param.id_farm_center, param.id_field, param.model_name)
    // },
    'farm/:id_farm_center/field/:id_field/edit': function (param) {
      jQuery('#ae_navbar_right').html('');



      // cleanPage();
      // var html=`<a onclick='location.hash="#farm/${param.id_farm_center}/field/${param.id_field}"' class="link" data-panel="left"><i class="f7-icons">chevron_left</i><span class="if-not-md">${('Back')}</span></a>`;
      // jQuery('.left').html(html);
      fs.edit_field(param.id_farm_center, param.id_field);
      // fairshare_oo.show_operation(param.id_farm_center, param.id_field);
    },

    'farm/:id_farm_center/field/:id_field/operation': function (param) {
      // cleanPage();
      var html=`<a onclick='location.hash="#farm/${param.id_farm_center}/field/${param.id_field}"' class="link" data-panel="left"><i class="f7-icons">chevron_left</i><span class="if-not-md">${('Back')}</span></a>`;
      jQuery('.left').html(html);

      fairshare_oo.show_operation(param.id_farm_center, param.id_field);
    },
    'farm/:id_farm_center/field/:id_field/new_operation': function (param) {
      // cleanPage();
      var html=`<a onclick='location.hash="#farm/${param.id_farm_center}/field/${param.id_field}/operation"' class="link" data-panel="left"><i class="f7-icons">chevron_left</i><span class="if-not-md">${('Back')}</span></a>`;
      jQuery('.left').html(html);

      fairshare_oo.operation(param.id_farm_center, param.id_field);
    },
    'farm/:id_farm_center/field/:id_field/new_operation/:id_crop_operation': function (param) {
      cleanPage();
      // var html=`<a onclick='location.hash="#farm/${param.id_farm_center}/field/${param.id_field}/operation"' class="link" data-panel="left"><i class="f7-icons">chevron_left</i><span class="if-not-md">${('Back')}</span></a>`;
      // jQuery('.left').html(html);

      var id_operation=null;
      fairshare_oo.operation(param.id_farm_center, param.id_field, id_operation, param.id_crop_operation);
    },
    'farm/:id_farm_center/field/:id_field/operation/:id_operation': function (param) {
      cleanPage();
      // var html=`<a onclick='location.hash="#farm/${param.id_farm_center}/field/${param.id_field}/operation"' class="link" data-panel="left"><i class="f7-icons">chevron_left</i><span class="if-not-md">${('Back')}</span></a>`;
      // jQuery('.left').html(html);

      fairshare_oo.operation(param.id_farm_center, param.id_field, param.id_operation);
    },
    'farm/:id_farm_center/field/:id_field/observation': function (param) {
      // cleanPage();
      var html=`<a onclick='location.hash="#farm/${param.id_farm_center}/field/${param.id_field}/"' class="link" data-panel="left"><i class="f7-icons">chevron_left</i><span class="if-not-md">${('Back')}</span></a>`;
      jQuery('.left').html(html);

      fairshare_oo.show_observation(param.id_farm_center, param.id_field);
    },
    'farm/:id_farm_center/field/:id_field/new_observation': function (param) {
      // cleanPage();
      var html=`<a onclick='location.hash="#farm/${param.id_farm_center}/field/${param.id_field}/observation"' class="link" data-panel="left"><i class="f7-icons">chevron_left</i><span class="if-not-md">${('Back')}</span></a>`;
      jQuery('.left').html(html);

      fairshare_oo.observation(param.id_farm_center, param.id_field);
    },
    'farm/:id_farm_center/field/:id_field/new_observation/:id_survey_schema': function (param) {
      cleanPage();
      // var html=`<a onclick='location.hash="#farm/${param.id_farm_center}/field/${param.id_field}/observation"' class="link" data-panel="left"><i class="f7-icons">chevron_left</i><span class="if-not-md">${('Back')}</span></a>`;
      // jQuery('.left').html(html);

      var id_observation=null;

      fairshare_oo.observation(param.id_farm_center, param.id_field, id_observation, param.id_survey_schema);
    },    
    'farm/:id_farm_center/field/:id_field/observation/:id_observation': function (param) {
      cleanPage();
      // var html=`<a onclick='location.hash="#farm/${param.id_farm_center}/field/${param.id_field}/observation"' class="link" data-panel="left"><i class="f7-icons">chevron_left</i><span class="if-not-md">${('Back')}</span></a>`;
      // jQuery('.left').html(html);

      fairshare_oo.observation(param.id_farm_center, param.id_field, param.id_observation);
    },
    'explorer': function (param) {
      cleanPage();

      fairshare_explorer.home_explorer();
    },
    
    'farm/:id_farm_center/zoo/:specie/gruppi': function (param) {
      // cleanPage();
      changeBack(`farm/${param.id_farm_center}/`);
      fs_zoo.gruppi_home(param.id_farm_center, param.specie);
    },
    'farm/:id_farm_center/zoo/:specie/gruppi/new': function (param) {
      cleanPage();


      fs_zoo.gruppi_new(param.id_farm_center, param.specie);
    },

    'farm/:id_farm_center/zoo/:specie/gruppi/:id_group': function (param) {
      // cleanPage();
      changeBack(`farm/${param.id_farm_center}/zoo/${param.specie}/gruppi`);

      fs_zoo.single_gruop_view(param.id_group, param.id_farm_center, param.specie);
    },

    'farm/:id_farm_center/zoo/:specie/anagrafica': function (param) {
      // cleanPage();
      changeBack(`farm/${param.id_farm_center}/`);
      fs_zoo.anagrafe_home(param.id_farm_center, param.specie);
    },
    'farm/:id_farm_center/zoo/:specie/anagrafica/new': function (param) {
      cleanPage();


      fs_zoo.anagrafe_new(param.id_farm_center, param.specie);
    },
    'farm/:id_farm_center/zoo/:specie/anagrafica/new/:code_bdn': function (param) {
      cleanPage();


      fs_zoo.anagrafe_new(param.id_farm_center, param.specie, param.code_bdn, param.code_bdn);
    },
    'farm/:id_farm_center/zoo/:specie/anagrafica/view/:id_animal': function (param) {
      // cleanPage();
      changeBack(`farm/${param.id_farm_center}/zoo/${param.specie}/anagrafica`);

      fs_zoo.anagrafe_view(param.id_animal, param.id_farm_center, param.specie);
    },

    'farm/:id_farm_center/zoo/:specie/anagrafica/view/:id_animal/from_group': function (param) {
      cleanPage();
      // changeBack(`farm/${param.id_farm_center}/zoo/${param.specie}/anagrafica`);

      fs_zoo.anagrafe_view(param.id_animal, param.id_farm_center, param.specie);
    },


    'farm/:id_farm_center/zoo/:specie/anagrafica/edit/:id_animal': function (param) {
      cleanPage();

      fs_zoo.anagrafe_edit(param.id_animal, param.id_farm_center, param.specie);
    },
    'farm/:id_farm_center/zoo/:specie/anagrafica/remove/:id_animal/:type_out': function (param) {
      cleanPage();

      fs_zoo.anagrafe_remove(param.id_animal, param.id_farm_center, param.specie, param.type_out);
    },

    //marketing for each farm center
    'farm/:id_farm_center/marketing': function (param) {
      cleanPage();

      fs_marketing.marketing_home(param.id_farm_center);
    },

    //warehouse for each farm center
    'farm/:id_farm_center/warehouse': function (param) {
      cleanPage();

      fs_warehouse.warehouse_home(param.id_farm_center);
    },
    'farm/:id_farm_center/warehouse/new/:id_product_type': function (param) {
      cleanPage();

      fs_warehouse.warehouse_new(param.id_farm_center, param.id_product_type);
    },
    'farm/:id_farm_center/warehouse/edit/:id_product_type/:id_product': function (param) {
      cleanPage();

      fs_warehouse.warehouse_edit(param.id_farm_center, param.id_product_type, param.id_product);
    }
  });


  return router;
}



function changeBack(hash){
  var html=`
    <a 
      onclick='location.hash="${hash}"' 
        class="link" data-panel="left">
          <i class="f7-icons">chevron_left</i>
          <span class="if-not-md">${('Back')}</span>
    </a>`;
  jQuery('.left').html(html);
}
