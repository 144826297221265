/*jshint esversion: 6 */

// ANCORA NON UFFICIALE COME FILE


// Vanno messi i permessi come in FindMyBike

import global from '../global.js';
import storage from '../storage.js';
import * as tools from '../tools.js';

function list(success){
  console.log("list");

  if(!global.is_cordova()){
    var devices=[
      {
        name:"Klean",
        id: "00:21:3E:92:87:05",
        address: "00:21:3E:92:87:05"
      },
    ];
    success(devices);
  }
  else{
    // bluetoothSerial.list(function(devices) {
      success(devices);
    // });
  }
}

export function bluetooth(id_farm_center){
  var permissions = null;
    

  if (global.is_cordova() && cordova.plugins && cordova.plugins.permissions) {
    permissions = cordova.plugins.permissions;
  }

  if (global.is_cordova() && device.platform.toLowerCase() == "android" && device.version > "11") {
    console.log("Permissions", permissions);
    if (permissions) {
      var permissionsToCheck = [
        // permissions.ACCESS_COARSE_LOCATION,
        permissions.ACCESS_FINE_LOCATION,
        // permissions.ACCESS_BACKGROUND_LOCATION,
        permissions.BLUETOOTH_SCAN,
        permissions.BLUETOOTH_CONNECT,
        // permissions.BLUETOOTH_PRIVILEGED,
        // permissions.NEARBY_WIFI_DEVICES,
        // permissions.BLUETOOTH
      ];

      permissions.checkPermission(permissionsToCheck, function(status) {
        if (status.hasPermission) {
          // All the requested permissions are already granted
          setCyclicScan(id_farm_center)
        } else {
          // At least one permission is not granted yet
          var remainingPermissions = permissionsToCheck.filter(function(permission) {
            return !status.hasPermission[permission];
          });
          requestRemainingPermissions(remainingPermissions,id_farm_center);
        }
      }, function(w) {
        console.log("Warning", w);
        console.error("Impossibile verificare i permessi.");
      });
    }
  } else {
    setCyclicScan(id_farm_center)
  }
}

function requestRemainingPermissions(permissions,id_farm_center) {
  if (permissions.length > 0) {
    var permission = permissions.pop();
    cordova.plugins.permissions.requestPermission(permission, function(status) {
      if (status.hasPermission) {
        // Permission granted
        requestRemainingPermissions(permissions, id_farm_center);
      } else {
        // Permission denied
        console.warn("Il permesso " + permission + " non è stato concesso.");
        alert("Il permesso " + permission + " è necessario per utilizzare questa funzionalità.");
        requestRemainingPermissions(permissions, id_farm_center);
      }
    }, function(e) {
      console.log("Error", e);
      console.error("Impossibile richiedere i permessi.");
    });
  } else {
    // All permissions have been granted
    setCyclicScan(id_farm_center)
  }
}

function setCyclicScan(id_farm_center) {
  console.log("setCyclicScan");
  var s = storage.get();
  var minutes = 1;
  if (s.minutes_range) {
    minutes = s.minutes_range;
    console.log("Minutes in jStorage: " + minutes);
  }
  
  if (typeof ble !== "undefined" && ble.isEnabled) {
    ble.isEnabled(
      function () {
        bluetooth_connect(id_farm_center);
      },
      function () {
        // Bluetooth is not enabled, notify the user or redirect them to the settings
        console.log("Bluetooth is not enabled");
        // Notify the user

        if (window.cordova && window.cordova.plugins.settings) {
          console.log('openNativeSettingsTest is active');
          // alert("Please enable Bluetooth to use this feature.");

          window.cordova.plugins.settings.open("bluetooth", function() {
              console.log('opened settings');

              setTimeout(function() {
                setCyclicScan(id_farm_center);
              }, 25000);
              //  setCyclicScan();
            },
            function () {
              console.log('failed to open settings');
              alert("Please enable Bluetooth to use this feature.");
            }
          );
        } else {
          alert("Please enable Bluetooth to use this feature.");
        }
      }
    );
  } else {
    console.log("Bluetooth library or isEnabled() function not available");
    // Handle the case where the Bluetooth library or isEnabled() function is not available
  }
}


//funzione principale di analisi della connessione
export function bluetooth_connect(id_farm_center){


  var id_farm_center=id_farm_center;
  if(!id_farm_center){
    id_farm_center=global.id_farm_center_bluetooth;
  }

  if(typeof ble == 'undefined'){

    global.theme.render({
      "title": "Attenzione",
      "content": `Il bluetooth non è disponibile su questo dispositivo. 
        <p>Per poter utilizzare questa funzionalità è necessario scaricare l'app dallo store.</p>
      `,
    });

    let payload={
      cod_bdn: "xxxxxx",
      cod_raw: "ble_not_found",
      id_farm_center:id_farm_center,
    }
    save_log_bt(payload);

    return;
  }


  var checkDevices=true;

  if(global.connected_device){
    ble.isConnected(global.connected_device, function() {
      checkDevices=false;
      console.log("is connected");
      device_connected(global.connected_device, id_farm_center);
    }
    , function() {
      console.log("is not connected");
      checkDevices=true;
    });
  }


  if(checkDevices){

    global.app.dialog.progress("Ricerca del bluetooth in corso..");

    var html='';
    html += `
    <div id="bluetooth">
      <div class="block-title">Dispositivi non accoppiati</div>
      <div class="unpaired list">
        <ul>
        </ul>
      </div>         
    </div>`;
    html+='<div id="readonly" style="margin: 15px;"></div>';

    var content={'title': '', 'content': html, exclude_card:true};
    global.theme.render(content);

    var devices=[];
    
    ble.startScan([], function(device) {
      devices.push(device);
      console.log(JSON.stringify(device));
    }, function (error) {
      console.log("Scanning failed: " + error);
    });
    setTimeout(function(){
      console.log("stope scan");
      ble.stopScan(
        function() {

          // close dialog
          global.app.dialog.close();
          
          console.log("stopScan success");
          jQuery('.unpaired ul').html('');
          jQuery.each(devices, function(index, device) {
            var html='';

            html+='<li class="bluetooth_device" mac_address="'+device.id+'">';
            html+='<div class="item-content">';

              html+='<div class="item-inner">';
                var mac_address=device.id;
                var label=device.id;
                var className="";
                if (device.name) {
                  label=device.name;
                  if(label.startsWith('RS420')){
                    className="lettore";
                    label+=" (Lettore Chip)";
                  }
                }
                if(device.address=="4C:E0:DB:8B:FD:DD"){
                  console.log("device trovato");
                  className="lettore_bolo";
                  label+=" (Cell Iride)";
                }

                html+='<div class="item-title '+className+'" id="'+mac_address+'">'+label+'</div>';
              html+='</div>';
            html+='</div>';
          html+='</li>';
          jQuery('.unpaired ul').append(html);
          });
        },
        function() {
          console.log("stopScan failed");
        }
      );
    }, 5000);

    jQuery(document).on('click', '.bluetooth_device', function(){
      
      var mac_address=jQuery(this).attr('mac_address');
      console.log("mac_address: "+mac_address);
      var label=jQuery(this).find('.item-title').html();
      console.log("label: "+label);

      ble.connect(mac_address, function(peripheral) {
        console.log("connected");

        global.connected_device=mac_address;
        global.readServiceUUID = "";
        global.readCharacteristicUUID = "";
        global.writeServiceUUID = "";
        global.writeCharacteristicUUID = "";

        jQuery.each(peripheral.characteristics, function(index, characteristic) {
          console.log(characteristic);
          if (characteristic.properties.indexOf('Notify') !== -1 && global.readServiceUUID == "" && global.readCharacteristicUUID == "") {
            // check if in properties is also write but not read
            var skip=false;
            if (characteristic.properties.indexOf('Write') !== -1 && characteristic.properties.indexOf('Read') == -1) {
              skip=true;
            }
            if(!skip){
              global.readServiceUUID = characteristic.service;
              global.readCharacteristicUUID = characteristic.characteristic;
            }
          }
          if (characteristic.properties.indexOf('WriteWithoutResponse') !== -1 && global.writeServiceUUID == "" && global.writeCharacteristicUUID == "") {
            global.writeServiceUUID = characteristic.service;
            global.writeCharacteristicUUID = characteristic.characteristic;

          }
        });

        console.log("GLOBAL", global.readServiceUUID, global.readCharacteristicUUID, global.writeServiceUUID, global.writeCharacteristicUUID);

        // console.log(JSON.stringify(peripheral));
        console.log(peripheral);

        device_connected(mac_address, id_farm_center);

      }, function() {
        console.log("not connected");
      });
    });
  }
}


//la funzione
function device_connected(mac_address, id_farm_center){
  console.log("10. connected");

  global.app.ptr.destroy('.ptr-content');
  global.app.dialog.close();

  pageBluetoothConnected(mac_address, id_farm_center);

}

function pageBluetoothConnected(mac_address, id_farm_center){

  var html='';
  html+='<div class="block-title">L\'app è connessa in maniera corretta con il bluethoot.</div>';
  html+='<div class="block block-strong">Adesso puoi iniziare ad usare il dispositivo per leggere.';
  html+='</div>';
  html+="<button class='button button-fill color-red' id='bt_disconnect'>Disconnettiti</button>";

  // textarea da poter inviare
  html += `<div class="list no-hairlines-md">
            <ul>
              <li class="item-content item-input">
                <div class="item-inner">
                  <div class="item-title item-label">Messaggio da inviare</div>
                  <div class="item-input-wrap">
                    <input id="toSend" type="text" placeholder="Messaggio" />
                    <span class="input-clear-button"></span>
                  </div>
                </div>
              </li>
            </ul>
            <ul>
              <li class="item-content">
                <div class="item-inner">
                  <button class='button button-fill color-green' id='send_serial'>Invia</button>
                </div>
              </li>
            </ul>
          </div>`;
            


  jQuery('#bluetooth').html(html);

  jQuery('#ricevi_serial').click(function(){
    readBluetooth(mac_address);
  });

  jQuery('#send_serial').click(function(){
    var text=jQuery('#toSend').val();
    if(global.is_cordova()){
      
      // var data = new Uint8Array(1);
			// data[0] = 0x31;

      ble.writeWithoutResponse(mac_address, global.writeServiceUUID, global.writeCharacteristicUUID, stringToBytes(text), function(){
        console.log("scritto");
        jQuery('#toSend').val('');
        jQuery('#readonly').append('<br><span style="float:right;">'+text+'</span>');

      }, function(error){
        console.log("non scritto");
        console.log(error);
      });  
    }
    else{
      jQuery('#readonly').append('<br><span style="float:right;">'+text+'</span>');
      jQuery('#toSend').val('');

      // alert("Attenzione errore nell'app -  manca il modulo BT");
    }
  });

  

  //attiva la lettura

  // readBluetooth(mac_address);
  var byte=new Uint8Array();
  var onData = function (buffer) {
    console.log("Buffer", buffer);
		// Decode the ArrayBuffer into a typed Array based on the data you expect
		// var data = new Uint8Array(buffer);
    byte=_appendBuffer(byte, buffer);

    var base64 = _arrayBufferToBase64(byte);
    var base64_single = _arrayBufferToBase64(buffer);

    //decodifica il Binario in una stringa
    var  last_code=window.atob(base64);
    var  last_code_single=window.atob(base64_single);

    // console.log("last_code_single", last_code_single);
    // console.log("last_code", last_code);

    //Se vede un VQ== svuota l'array, altrimenti a volte salta tutto (si accavallano?)
    if(base64_single=='VQ=='){
      last_code="";
      byte=new Uint8Array();
    }

    //se la stringa raggiunge una lunghezza limite la processa
    if(last_code.length>=30){
      console.log("last_code", last_code);
      jQuery('#readonly').append('<br><span style="float:left;">'+last_code+'</span>');

      save_code(last_code, id_farm_center, base64);
      byte=new Uint8Array();
    }
	};

	ble.startNotification(mac_address, global.readServiceUUID, global.readCharacteristicUUID, onData, 
    function(error){
      console.log("non scritto");
      console.log(error);
    });

  // Quando clicco su un device già connesso, effettuo la disconnessione
  jQuery("#bt_disconnect").click(function() {
    global.app.dialog.progress("Disconnessione in corso..");

    disconnectDevice(mac_address, id_farm_center);
  });

}

function disconnectDevice(mac_address, id_farm_center){

  ble.disconnect(mac_address, function() {
    console.log("Disconnected " + mac_address);
    global.app.dialog.close();

    bluetooth_connect(id_farm_center);
    // location.hash="#bluetooth";
  }, function() {
    console.log("Disconnect " + mac_address + " error");
    global.app.dialog.close();

    bluetooth_connect(id_farm_center);
    // location.hash="#bluetooth";
  });

}


function _arrayBufferToBase64( buffer ) {
 var binary = '';
 var bytes = new Uint8Array( buffer );
 var len = bytes.byteLength;
 for (var i = 0; i < len; i++) {
   binary += String.fromCharCode( bytes[ i ] );
 }

 console.log("binary", binary);
 console.log("window.btoa( binary )", window.btoa( binary ));
 return window.btoa( binary );
}


function _base64ToArrayBuffer(base64) {
    var binary_string =  window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array( len );
    for (var i = 0; i < len; i++)        {
        bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes;
}

//Funzione che unisce due binari (ArrayBuffer)
var _appendBuffer = function(buffer1, buffer2) {
  var tmp = new Uint8Array(buffer1.byteLength + buffer2.byteLength);
  tmp.set(new Uint8Array(buffer1), 0);
  tmp.set(new Uint8Array(buffer2), buffer1.byteLength);
  return tmp.buffer;
};

function readBluetooth(mac_address){
  console.log("Leggo bluetooth");
}

function stringToBytes(string) {
	var array = new Uint8Array(string.length);
	for (var i = 0, l = string.length; i < l; i++) {
		array[i] = string.charCodeAt(i);
	}
	return array.buffer;
}



function save_code(dato_grezzo, id_farm_center, base64){

  var id_farm_center=id_farm_center;
  if(!id_farm_center){
    id_farm_center=global.id_farm_center_bluetooth;
  }

  console.log("dato_grezzo",dato_grezzo);

  var dato_ok=dato_grezzo;

  /*
    Abbiamo due codici 
     UFDXB    380 053000464596*7E13
     UHDX     999000000006361�*9696
  */

  const regex = /\d{15}|\d{3} \d{12}/g;
  if(dato_grezzo.match(regex)){
    let str=dato_grezzo.match(regex)[0];
    str=str.replace(" ","");
    if(str.substr(0,3)=='380'){
      dato_ok="IT"+str.substr(3,12);
    }
    else{
      dato_ok=str;
    }
  }
  else{
    var asterix=(dato_grezzo.indexOf("*"));
    var index999=(dato_grezzo.indexOf("999"));
    var index380=(dato_grezzo.indexOf("380"));
    
    if(index999>-1){
      dato_ok=(dato_grezzo.substr(index999,15) );
    }
    else if(asterix>-1){
      // Attenzione: il sistema adesso sta forzando italia (380) anche se non c'è un controllo
      if(dato_grezzo.indexOf("HDX")>-1){
        dato_ok="IT"+(dato_grezzo.substr(asterix-13,12) );
      }
      else{
        dato_ok="IT"+(dato_grezzo.substr(asterix-12,12) );
      }
    }
    else if(index380>-1){
      dato_ok="IT"+(dato_grezzo.substr(index380,12) );
    }
  }

  //pulisce da evetuali codici non ascii
  dato_ok=cleanString(dato_ok);

  console.log("dato_ok", dato_ok);

  
  var s=storage.get();
  var animal_exist=false;
  // var guid=null;
  // jQuery.each(s.animal,function(k,v){
  //   if (v.code_bdn==dato_ok) {
  //     animal_exist=true;
  //     guid=v.guid;
  //   }
  // });
  var animal=s.animal.filter(function(f){
    return f.code_bdn==dato_ok && f.id_farm_center==id_farm_center;
  });

  if(animal.length>0){
    animal_exist=true;
  }

  if (animal_exist) {
    console.log(animal[0]);
    animal=animal[0];
    console.log("open the animal");
    location.hash="#farm/"+animal.id_farm_center+"/zoo/sheep/anagrafica/view/"+animal.id_animal;
  }
  else{
    // console.log("Animal not found");
    location.hash="#farm/"+id_farm_center+"/zoo/sheep/anagrafica/new/"+dato_ok;
  }


  let payload={
    cod_bdn: dato_ok,
    cod_raw: base64,
    id_farm_center:id_farm_center,
  };

  save_log_bt(payload);

  //get animal la lasciamo perche serve per loggare le letture ma a lungo termine va eliminata
  let urladdr = global.base_call+"bt_log";
  jQuery.ajax({
    type: "POST",
    url: urladdr,
    headers: {
      'Authorization':'Bearer '+s.user.access_token
    },
    data:JSON.stringify(payload),
    contentType: "application/json",
    dataType: "json",
    success: function(data){
      try{
        console.log(JSON.stringify(data));
        console.log("open the animal");
      }
      catch(e){
        alert("E7"+e);
      }
    },
    error: function(err){
      //alert("E3"+JSON.stringify(err));
    }
  });
}

//funzioe per pulire la stringa da eventuali caratteri non ascii
function cleanString(input) {
  var output = "";
  for (var i=0; i<input.length; i++) {
      if (input.charCodeAt(i) <= 127) {
          output += input.charAt(i);
      }
  }
  return output;
}

function save_log_bt(data){

  let s=storage.get();
  if(!s.log_bluetooth){
    s.log_bluetooth=[];
  }

  data.id_log_bluetooth=tools.getGuid();
  data.datetime=new Date().toISOString();

  data.sync=false;
  s.log_bluetooth.push(data);
  storage.save(s);	


}