import * as db from './data.js';
import global from '../../global.js';
import storage from '../../storage.js';
import t from '../../translate.js';
import * as ai from './ai.js';
import * as pheno from './pheno.js';

import * as fairshare_oo from './fairshare_oo.js';
import * as fairshare_soil from './fairshare_soil.js';
import { latLng } from 'leaflet';
import * as irri from './irri.js';
import * as nutri from './nutri.js';
import * as arcWidget from './arcWidget.js';
import * as plasmoWidget from './PlasmoWidgetSimple.js';


//prepare the field page with model data
export function prepareSections(id_field, fd, section){

  console.log("prepareSections",fd, section);

  //choose the section
  var run_section='pheno';


  var id_farm_center=fd.field.id_farm_center;
  var crop=db.getCrop(fd.field.id_crop);

  var sections=["pheno", "irri", "nutri", "pest"];

  if (crop.id_crop==3) {
    run_section='irri';
    sections=["irri", "nutri", "pest"];
  }

  if(section){
    run_section=section;
  }

  var tabs="";

  jQuery.each(sections,function(ks,s){
    tabs+=(`
      <button
        onclick="location.hash='#farm/${id_farm_center}/field/${id_field}/model/${s}'"
        class="model-feno button
          ${run_section==s?'button-active':""}
          ">
        ${s}
      </button>
    `);
  });

  jQuery("#model_result_home").html(`
    <div class="block block-strong inset">
      <p  class="segmented segmented-raised">${tabs}</p>

      <div id="section_result">
        <div id="section_result_title"></div>
        <div id="section_result_data"></div>
        <div id="section_result_model"></div>
        <div id="section_result_chart_button"></div>
      </div>
    </div>
  `);


  runSection(id_field, fd, run_section);

}

// export function modelChart(id_field,fd,model_name){
//   if (model_name=='irri') {
//     irri.drawIrriDetail(data_model,guid_farm,guid_field)
//   }
//   else {
//
//   }
// }


//run a specific section
function runSection(id_field, fd, run_section){


  if(run_section=='irri'){

    var irri_desc="";
    if(!fd.field.irrigation){
      irri_desc=`${t('Si prega di definire la')} <a class="link external" href="#farm/${fd.farm.id_farm_center}/field/${id_field}/edit">${t('tipologia di irrigazione')}</a> ${t('(se presente)')}`;
    }
    else if (fd.field.irrigation=='no'){
      irri_desc=t(`Campo non irriguo`);
    }
    else{
      irri_desc=`${t('Impianto')}: ${fd.field.irrigation} - ${t('Portata')} ${fd.field.irrigation_flow?fd.field.irrigation_flow+" l/s":t("assente")}`;
    }
    irri_desc+=` `
    irri_desc+="<br/>";

    var irrigation_log=db.filter_irrigation(fd);

    if(irrigation_log.length==0){
      irri_desc+=t(`Nessuna irrigazione inserita`)
    }
    else{
      var mm=0;
      jQuery.each(irrigation_log, function(ki,i){
        mm+=parseFloat(i.water);
      });
      irri_desc+=`${t('Irrigazioni inserite')}: ${irrigation_log.length} - ${mm} mm`
    }
    irri_desc+=` <a class="link external" href="#farm/${fd.farm.id_farm_center}/field/${id_field}/new_operation/2">${t('Aggiungi')}</a>`


    
    

    var checked='';
    if (fd.field.auto_irrigation) {
      checked=' checked ';
    }
    irri_desc+=`
      <div class="list simple-list">
        <ul>
          <li>
            <span>${t('Irrigazione automatica')}</span>
            <label class="toggle toggle-init">
              <input id="auto_irrigation" type="checkbox" ${checked}/>
              <span class="toggle-icon"></span>
            </label>
          </li>
        </ul>
      </div>

    `;
    jQuery("#section_result_data").html(irri_desc);

    jQuery('#auto_irrigation').change(function(){
      var activate_auto_irrigation=jQuery('#auto_irrigation').is(':checked');
      console.log(activate_auto_irrigation);

      var oldField=db.getFieldData(id_field).field;
      oldField.auto_irrigation=activate_auto_irrigation;

      db.updateField(oldField, id_field, function(){
        location.reload();
      });

    });

    var chart=`<button id="irri_chart" class="button button-fill button-primary">${t('Consulta il grafico')}</button>`
    jQuery('#section_result_chart_button').html(chart);
  }
  else if(run_section=='pheno'){

    var irri_desc="";
    if(!fd.field.cultivar){
      irri_desc=t(`Si prega di definire la cultivar del campo`);
    }
    else{
      irri_desc=`${t('Cultivar')}: ${fd.field.cultivar}`;
    }
    irri_desc+=` <a class="link external" href="#farm/${fd.farm.id_farm_center}/field/${id_field}/edit">${t('Modifica')}</a>`
    irri_desc+="<br/>";

    var pheno_log=db.filter_pheno(fd);

    if(pheno_log.length==0){
      irri_desc+=t(`Nessuna Fenologia inserita`);
    }
    else{

      var last_pheno=pheno_log[pheno_log.length-1];

      console.log("diego",last_pheno);
      irri_desc+=`${t('Fasi fenologiche inserite')}: ${pheno_log.length} - ${t('ultima')} BBCH: ${t(last_pheno.fasemedia)} ${last_pheno.date}`
    }
    irri_desc+=` <a class="link external" href="#farm/${fd.farm.id_farm_center}/field/${id_field}/new_observation/11">${t('Aggiungi')}</a>`

    

    jQuery("#section_result_data").html(irri_desc);

  }
  if(run_section=='nutri'){

    var ferti_log=db.filter_ferti(fd);
    var irri_desc="";


    if(fd && fd.field && !fd.field.yield_ha){
      irri_desc+=t(`Nessuna resa inserita: viene usata una resa standard di 50q/ha`)
      irri_desc+=` <a class="link external" href="#farm/${fd.farm.id_farm_center}/field/${id_field}/edit">${t('Aggiungi')}</a>`
      irri_desc+="<hr></hr>";
    }


    if(ferti_log.length==0){
      irri_desc+=t(`Nessuna Concimazione inserita`)
    }
    else{
      var npk={n:0, p:0, k:0};
      jQuery.each(ferti_log, function(ki,i){


        if(i.no3){
          npk.n+=(parseFloat(i.no3));
        }
        else if(i.nh4){
          npk.n+=(parseFloat(i.nh4));
        }
        else if(i.urea){
          npk.n+=(parseFloat(i.urea));
        }
        npk.p+=(parseFloat(i.p2o5));
        npk.k+=(parseFloat(i.k2o));
      });
      irri_desc+=`${t('Concimazioni inserite')}: ${ferti_log.length} - NPK: ${npk.n.toFixed(0)}-${npk.p.toFixed(0)}-${npk.k.toFixed(0)} `
    }
    irri_desc+=` <a class="link external" href="#farm/${fd.farm.id_farm_center}/field/${id_field}/new_operation/3">${t('Aggiungi')}</a>`
    irri_desc+="<hr></hr>";

    jQuery("#section_result_data").html(irri_desc);
  }

  //1. find if there are available models

  var available_models=fd.aemodel.filter(f=>f.section==run_section);

  if(fd.farm.id_station==0){
    jQuery("#section_result_model").html("<hr/>"+t("L'azienda non è collegata a nessuna stazione meteo"));
  }
  else if(available_models.length==0){
    jQuery("#section_result_model").html("<hr/>"+t("Non ci sono modelli disponibili"));
  }
  else{

    //2. allow the user to change model (if more themn 1 exists)
    var num_models=0;
    var opt="";
    jQuery.each(available_models, function(km, m){
      jQuery('#section_result_title').html(`<div class="row"><h3>${m.model_description}</h3> <span style="float:right;"><i class="f7-icons" id="refresh_model">arrow_clockwise</i></span></div>`);

      opt+=`<option value=${m.model_name}>${t(m.model_description)}</option>`;
      num_models++;
    });
    var ht="";
    //Mostra il selettore del modello se è più di uno
    ht+=`
        <div style="display:${num_models>1?"block":"none"}"><h4>${t('Modello')} <select id="choose_model">${opt}</select></h4></div>
        <div style="text-align:center;" id="model_result"><img src="icons/loading.svg" style="width:40%;" id="loading_chat" /></div>
    `;
    jQuery("#section_result_model").html(ht);

    //3. run the selected models
    jQuery("#choose_model").on("change", function(){
      var model_name=jQuery(this).val();

      jQuery('#model_result').html('<div id="model_result_'+model_name+'"><img src="icons/loading.svg" style="width:40%;" id="loading_chat" /></div>');

      runModel(id_field, fd, model_name);
    }).trigger("change");

    jQuery('#refresh_model').click(function(){
      var actual_model=jQuery('#choose_model').val();
      if (actual_model=='multi_pest') {
        actual_model=jQuery('#multi_pest').val();
      }
      console.log(jQuery('#choose_model').val());
      var s=storage.get();
      var today=global.getTodayDate();

      jQuery.each(s.model_cache,function(k,v){
        if (v.model_name==actual_model && v.id_field==id_field) {
          delete s.model_cache[k];
        }
      });

      if (!s.model_cache) {
        s.model_cache=[];
      }
      s.model_cache = s.model_cache.filter(function () { return true });

      storage.save(s);
      jQuery('#model_result_'+actual_model).html('<div id="model_result_'+actual_model+'"><img src="icons/loading.svg" style="width:40%;" id="loading_chat" /></div>');
      jQuery('#inner_model_result br').remove();

      jQuery('#section_result_chart_button').html('');
      jQuery('.alert-level').remove();
      jQuery('#ref_date').remove();

      var force_refresh=true;
      runModel(id_field, fd, actual_model, force_refresh);

      jQuery('.station_alert').remove();

      if(actual_model=='aeco_grape_powdery'){
        jQuery('#model_result_aeco_grape_powdery_ml').html('<div id="model_result_aeco_grape_powdery_ml"><img src="icons/loading.svg" style="width:40%;" id="loading_chat" /></div>');
        runModel(id_field, fd, 'aeco_grape_powdery_ml', force_refresh, actual_model);
      }
      else if(actual_model=='aeco_grape_plasmo'){
        jQuery('#model_result_aeco_grape_plasmo_ml').html('<div id="model_result_aeco_grape_plasmo_ml"><img src="icons/loading.svg" style="width:40%;" id="loading_chat" /></div>');
        runModel(id_field, fd, 'aeco_grape_plasmo_ml', force_refresh, actual_model);
      }

    });
  }
}

export function runModel(id_field, fd, model_name, force_refresh, from_secondary_model){

  console.log("runModel from_secondary_model", from_secondary_model);

  var today=(new Date()).toISOString().substring(0,10);
  //se il modello è di una stagione già terminata il modello finisce con la stagzione precedente
  var date_to=today;
  if(fd.season.date_to<today){
    date_to=fd.season.date_to;
  }

  var settings=db.getModelSettings(fd,model_name, date_to);

  if (force_refresh) {
    settings.force_refresh=force_refresh;
  }
  var cached_model=db.getModelFromCache(date_to, model_name, id_field);


  if (cached_model.length>0 && !force_refresh) {
    var response=cached_model[0].response;    
    manageModel(model_name, response, id_field);
    cleanDIVs(from_secondary_model);
  }
  else {
    if (model_name!='multi_pest') {
      var model_path=global.base_path;
      if(global.model_path){
        model_path=global.model_path;
      }
      jQuery.ajax({
        type: "POST",
        url: model_path+"runModel/"+model_name,
        data: JSON.stringify(settings)   ,
        dataType: "json",
        contentType: "application/json",
        success: function (response) {

          console.log(settings);
          if (response.ok) {
            delete settings.force_refresh;

            var setting_hash=global.hashCode(JSON.stringify(settings));

            db.insertModelResult(date_to, model_name, id_field, setting_hash, response);

            try {
              console.log(response);
              manageModel(model_name, response, id_field);

              console.log(from_secondary_model);

              cleanDIVs(from_secondary_model);
            }
            catch (e) {
              console.log(e);
            }

          }
          else {
            if(date_to.substring(5,7)<"06" && model_name=='dss_olivefly_complete'){
              // jQuery('#model_result_'+model_name).html(t("Il DSS sulla difesa dalla mosca dell'olivo si attiva da Giugno"));
              jQuery('#model_result_'+model_name).html(`<div style="border-radius: 30px;padding: 1px;" class="alert-level alert_level_info"><h2>${t("Il DSS sulla difesa dalla mosca dell'olivo si attiva da Giugno")}</h2></div>`);

            }
            else {
              jQuery('#model_result_'+model_name).html(response.message);

              if(response.error_messages && response.error_messages.length>0){
                jQuery.each(response.error_messages, function(k, v){
                  jQuery('#model_result_'+model_name).append("<br/>"+v);
                });
              }

            }
          }
        },
        error: function(){
          jQuery("#model_result_"+model_name).html(t("Modello non disponibile. Si prega di connettersi alla rete"));
        }
      });
    }
    else {
      manageModel(model_name, {"ok":true,"results":{},"final_value":{}}, id_field);
    }
  }
}

function cleanDIVs(from_secondary_model){
  if(from_secondary_model){
    if(from_secondary_model=='aeco_grape_powdery'){
      jQuery('.secondary_model #model_result_aeco_grape_powdery_ml>div>#plasmo').remove();
      jQuery('.secondary_model #model_result_aeco_grape_powdery_ml>div>#plasmo_ml_charts').remove();

      jQuery('.secondary_model #model_result_aeco_grape_powdery_ml>div>#gray').remove();
      jQuery('.secondary_model #model_result_aeco_grape_powdery_ml>div>#gray_ml_charts').remove();

      jQuery('.secondary_model #model_result_aeco_grape_powdery_ml>div>#powdery>h3').remove();



    }
    if(from_secondary_model=='aeco_grape_plasmo'){
      jQuery('.secondary_model #model_result_aeco_grape_plasmo_ml>div>#powdery').remove();
      jQuery('.secondary_model #model_result_aeco_grape_plasmo_ml>div>#powdery_ml_charts').remove();

      jQuery('.secondary_model #model_result_aeco_grape_plasmo_ml>div>#gray').remove();
      jQuery('.secondary_model #model_result_aeco_grape_plasmo_ml>div>#gray_ml_charts').remove();

      jQuery('.secondary_model #model_result_aeco_grape_plasmo_ml>div>#plasmo>h3').remove();
    }

    jQuery('.secondary_model #model_result_aeco_grape_plasmo_ml>div>hr').remove();
    jQuery('.secondary_model #model_result_aeco_grape_powdery_ml>div>hr').remove();
  }
}

function manageModel(model_name, response, id_field){
  if(response.ok){
    var fd=db.getFieldData(id_field);
    
    var fv=response.final_value;
    var results=response.results.values;
    
    
    if(model_name=="aeco_olive_pheno"){
      console.log(fv);
      
      jQuery('#model_result_'+model_name).html(`
      <div class="row">
      <div class="col-30">
      <div id="bbch_img"></div>
      </div>
      <div class="col-70">
      
            ${t('Fase prevalente')}:<br><b>${t(fv.stage)}</b>
            <br>
            ${t('Gradi giorno cumulati')}: <b>${fv.day_degree?(fv.day_degree).toFixed(1):"-"}</b>
            </div>
            </div>
            <hr>
            
            `);


      var demoGauge = global.app.gauge.create({
        el: '#model_result_'+model_name,
        type: 'semicircle',
        value: fv.perc_phase?(fv.perc_phase/100):1,
        size: 150,
        borderColor: '#4caf50',
        borderWidth: 10,
        valueText: fv.perc_phase?fv.perc_phase.toFixed(1)+'%':'100%',
        valueFontSize: 20,
        valueTextColor: '#4caf50',
        // labelText: "&nbsp",
        labelTextColor: '#4caf50',
      });
      jQuery('#model_result_'+model_name).append(`
        <div style="display:flex;justify-content: space-around;color:#4caf50;">
          <div style="float:left;"><b>${global.capitalizeFirstLetter(t(fv.stage))}</b></div>
          <div style="float:right;">${global.capitalizeFirstLetter(fv.next_stage_name?t(fv.next_stage_name):t('Fine'))}</div>
        </div>
      `);

      checkIfImageExists(`icons/pheno_icons/pheno_olivo/bbch_${fv.bbch}.png`, (exists) => {
        if (exists) {
          jQuery('#bbch_img').html(`<img src="icons/pheno_icons/pheno_olivo/bbch_${fv.bbch}.png" />`);
          console.log('Image exists. ')
        } else {
          console.error('Image does not exists.')
        }
      });

      var chart=`<button id="pheno_olive_chart" class="button button-fill button-primary">${t('Consulta il grafico')}</button>`
      jQuery('#section_result_chart_button').html(chart);

      jQuery('#pheno_olive_chart').unbind().click(function(){
        var dynamicSheet = global.app.sheet.create({
          content: `
            <div class="sheet-modal" style="height: 60%;">
              <div class="toolbar">
                <div class="toolbar-inner">
                  <div class="left"></div>
                  <div class="right">
                    <a class="link sheet-close">Done</a>
                  </div>
                </div>
              </div>
              <div class="sheet-modal-inner">
                <div class="block" id="pheno_olive_chart_div">
                </div>
              </div>
            </div>
          `,
          on: {
            open: function (sheet) {
              console.log('Sheet open');
              pheno.drawChart('pheno_olive_chart_div', response,'day_degree')
            },
            opened: function (sheet) {
              console.log('Sheet opened');
            },
          }
        });
        dynamicSheet.open();
      });
    }
    else if(model_name=="aeco_grape_pheno"){
      console.log(fv);
      jQuery('#model_result_'+model_name).html(`
      <div class="row">
      <div class="col-30">
      <div id="bbch_img"></div>
      </div>
      <div class="col-70">
      
            ${t('Fase prevalente')}:<br><b>${t(fv.stage)}</b>
            <br>
            ${t('Gradi giorno cumulati')}: <b>${fv.day_degree?(fv.day_degree).toFixed(1):"-"}</b>
            </div>
            </div>
            <hr>
            
            `);


      var demoGauge = global.app.gauge.create({
        el: '#model_result_'+model_name,
        type: 'semicircle',
        value: fv.perc_phase?(fv.perc_phase/100):1,
        size: 150,
        borderColor: '#4caf50',
        borderWidth: 10,
        valueText: fv.perc_phase?fv.perc_phase.toFixed(1)+'%':'100%',
        valueFontSize: 20,
        valueTextColor: '#4caf50',
        // labelText: "&nbsp",
        labelTextColor: '#4caf50',
      });
      jQuery('#model_result_'+model_name).append(`
        <div style="display:flex;justify-content: space-around;color:#4caf50;">
          <div style="float:left;"><b>${global.capitalizeFirstLetter(t(fv.stage))}</b></div>
          <div style="float:right;">${global.capitalizeFirstLetter(fv.next_stage_name?t(fv.next_stage_name):t('Fine'))}</div>
        </div>
      `);

      checkIfImageExists(`icons/pheno_icons/pheno_vite/bbch_${fv.bbch}.png`, (exists) => {
        if (exists) {
          jQuery('#bbch_img').html(`<img src="icons/pheno_icons/pheno_vite/bbch_${fv.bbch}.png" />`);
          console.log('Image exists. ')
        } else {
          console.error('Image does not exists.')
        }
      });

      var chart=`<button id="pheno_grape_chart" class="button button-fill button-primary">${t('Consulta il grafico')}</button>`
      jQuery('#section_result_chart_button').html(chart);

      jQuery('#pheno_grape_chart').unbind().click(function(){
        var dynamicSheet = global.app.sheet.create({
          content: `
            <div class="sheet-modal" style="height: 60%;">
              <div class="toolbar">
                <div class="toolbar-inner">
                  <div class="left"></div>
                  <div class="right">
                    <a class="link sheet-close">Done</a>
                  </div>
                </div>
              </div>
              <div class="sheet-modal-inner">
                <div class="block" id="pheno_grape_chart_div">
                </div>
              </div>
            </div>
          `,
          // Events
          on: {
            open: function (sheet) {
              console.log('Sheet open');
              pheno.drawChart('pheno_grape_chart_div', response,'day_degree')
            },
            opened: function (sheet) {
              console.log('Sheet opened');
            },
          }
        });
        dynamicSheet.open();
      });
    }
    else if(model_name=="dss_irrigation"){
      irri.drawIrriWidget('#model_result_'+model_name,response);

      jQuery('#irri_chart').unbind().click(function(){

        var dynamicSheet = global.app.sheet.create({
          content: `
          <div class="sheet-modal" style="height: 60%;">
              <div class="toolbar">
                <div class="toolbar-inner">
                  <div class="left"></div>
                  <div class="right">
                    <a class="link sheet-close">Done</a>
                  </div>
                </div>
              </div>
              <div class="sheet-modal-inner">
                <div class="block" id="irri_chart_div">
                </div>
              </div>
            </div>
          `,
          // Events
          on: {
            open: function (sheet) {
              console.log('Sheet open');

              irri.drawChart(response.results.values,'irri_chart_div');
            },
            opened: function (sheet) {
              console.log('Sheet opened');
            },
          }
        });
        dynamicSheet.open();

        
        // location.hash=`farm/${fd.farm.id_farm_center}/field/${id_field}/model/irri/chart`
      });
    }
    else if(model_name=="dss_nutrition"){
      nutri.drawNutriWidget('#model_result_'+model_name,response);
    }
    else if(model_name=="olive_bactrocera_mortality"){
      jQuery("#model_result_"+model_name).html(`
      ${t('Data')}: ${fv.datetime}<br/>
      ${t('Mortalità')}: ${fv.mortality}<br/>
      ${t('Mortalità settimanale')}: ${fv.sum_mortality_weekly}<br/>
    `);
    }
    else if(model_name=="aeco_grape_lobesia"){
      jQuery('#model_result_'+model_name).addClass('sipm_body');
      arcWidget.drawSummary(model_name,'#model_result_'+model_name,response,{});

      var chart_btn=`<button style="margin-top:10px;" id="lobesia_chart" class="button button-fill button-primary">${t('Consulta il grafico')}</button>`

      jQuery('#section_result_chart_button').html(chart_btn);

      jQuery('#lobesia_chart').unbind().click(function(){
        var dynamicSheet = global.app.sheet.create({
          content: `
            <div class="sheet-modal" style="height: 60%;">
              <div class="toolbar">
                <div class="toolbar-inner">
                  <div class="left"></div>
                  <div class="right">
                    <a class="link sheet-close">Done</a>
                  </div>
                </div>
              </div>
              <div class="sheet-modal-inner">
                <div class="block" id="lobesia_chart_div">
                </div>
              </div>
            </div>
          `,
          // Events
          on: {
            open: function (sheet) {
              console.log('Sheet open');
              
              arcWidget.drawChart(model_name, 'lobesia_chart_div', response.results.values, {})
            },
            opened: function (sheet) {
              console.log('Sheet opened');
            },
          }
        });
        dynamicSheet.open();
      });
    }
    else if (model_name=='dss_olivefly_complete') {
      jQuery("#model_result_"+model_name).html(``);
      ai.aiModelFromResult('model_result_'+model_name,response)
      
      jQuery('#data_ovideposizioni').unbind().change(function(){
        
        var oldField=db.getFieldData(id_field).field;
        oldField.data_ovideposizione=jQuery(this).val();
        
        db.updateField(oldField, id_field, function(){
          location.reload();
        });
      });
      
    }
    else if(model_name=="aeco_grape_powdery"){

      // debugger
      if(response && response.final_value){
        if(response.final_value.ok==false){
          jQuery("#model_result_"+model_name).html(`<div style="border-radius: 30px;padding: 1px;" class="alert-level alert_level_info"><h2>${t(response.final_value.message)}</h2></div>`);

          return;
        }
      }

      jQuery("#model_result_"+model_name).html(``);

      if(!fv.model_end){
        ai.drawGauge('#model_result_'+model_name,(fv.risk_index));
      }
      else{
        jQuery("#model_result_"+model_name).html(`<div style="border-radius: 30px;padding: 1px;" class="alert-level alert_level_info"><h2>${t('Il ciclo produttivo della coltura è terminato')}</h2></div>`);
      }
      
      var chart_btn=`<button style="margin-top:10px;" id="powdery_chart" class="button button-fill button-primary">${t('Consulta il grafico')}</button>`
      jQuery('#section_result_chart_button').html(chart_btn);

      jQuery('#powdery_chart').unbind().click(function(){
        var dynamicSheet = global.app.sheet.create({
          content: `
            <div class="sheet-modal" style="height: 60%;">
              <div class="toolbar">
                <div class="toolbar-inner">
                  <div class="left"></div>
                  <div class="right">
                    <a class="link sheet-close">Done</a>
                  </div>
                </div>
              </div>
              <div class="sheet-modal-inner">
                <div class="block" id="powdery_chart_div">
                </div>
              </div>
            </div>
          `,
          // Events
          on: {
            open: function (sheet) {
              console.log('Sheet open');
              ai.drawAiChart('powdery_chart_div', response,'risk_index')
            },
            opened: function (sheet) {
              console.log('Sheet opened');
            },
          }
        });
        dynamicSheet.open();
      });
    }
    else if(model_name=="aeco_grape_plasmo"){
      plasmoWidget.drawSummary('#model_result_'+model_name,response,{});
      // jQuery('#model_result_'+model_name).parent().parent().append(``);
      jQuery('.sipm_pictogram').css('display','none');


      var chart_btn=`<button style="margin-top:10px;" id="plasmo_chart" class="button button-fill button-primary">${t('Consulta il grafico')}</button>`
      jQuery('#section_result_chart_button').html(chart_btn);

      jQuery('#plasmo_chart').unbind().click(function(){
        var dynamicSheet = global.app.sheet.create({
          content: `
            <div class="sheet-modal" style="height: 60%;">
              <div class="toolbar">
                <div class="toolbar-inner">
                  <div class="left"></div>
                  <div class="right">
                    <a class="link sheet-close">Done</a>
                  </div>
                </div>
              </div>
              <div class="sheet-modal-inner">
                <div class="block" id="plasmo_chart_div">
                </div>
              </div>
            </div>
          `,
          // Events
          on: {
            open: function (sheet) {
              console.log('Sheet open');
              plasmoWidget.drawChart('plasmo_chart_div', response)
            },
            opened: function (sheet) {
              console.log('Sheet opened');
            },
          }
        });
        dynamicSheet.open();
      });




    }
    else if(model_name=="aeco_grape_ml" || model_name=="aeco_grape_powdery_ml" || model_name=="aeco_grape_plasmo_ml"){
      if(response && response.result_summary){
        if(response.result_summary.message=='Model not started'){
          jQuery("#model_result_"+model_name).html(`<div style="border-radius: 30px;padding: 1px;" class="alert-level alert_level_info"><h2>${t(response.result_summary.message)}</h2></div>`);
          return;
        }
      }

      var html=`
      <div>

        <div id="powdery"><h3> Powdery mildew (Erisyphe necator)</h3><div id="model_powdery"></div></div>
        <button style="margin-top:10px;" id="powdery_ml_charts" class="ml_chart button button-fill button-primary">${t('Consulta il grafico')}</button>

        <hr>

        <div id="plasmo"><h3>Downy mildew (Plasmopara viticola)</h3><div id="model_plasmo"></div></div>
        <button style="margin-top:10px;" id="plasmo_ml_charts" class="ml_chart button button-fill button-primary">${t('Consulta il grafico')}</button>

        <hr>

        <div id="gray"><h3>Gray mold (Botrytis cinerea)</h3><div id="model_gray"></div></div>
        <button style="margin-top:10px;" id="gray_ml_charts" class="ml_chart button button-fill button-primary">${t('Consulta il grafico')}</button>
      </div>
      `;
      jQuery("#model_result_"+model_name).html(`${html}`);

      jQuery.each(response.result_summary,function(k,v){
        if(k=='last_record'){
          
          if(v=='End of season'){
            jQuery("#model_result_"+model_name).html(`<div style="border-radius: 30px;padding: 1px;" class="alert-level alert_level_info"><h2>${t('Il ciclo produttivo della coltura è terminato')}</h2></div>`);
          }
          else{
            if(typeof v.p_inf!='undefined' && v.p_inf!=null){
              ai.drawGauge('#model_result_'+model_name,(100*v.p_inf));
            }
            else{
              ai.drawGauge('#model_result_'+model_name,(100*v.last_record.p_inf));
            }
          }

        }

      });


      // var chart_btn=`<button style="margin-top:10px;" id="powdery_chart" class="button button-fill button-primary">${t('Consulta il grafico')}</button>`
      // jQuery('#section_result_chart_button').html(chart_btn);

      jQuery('.ml_chart').unbind().click(function(){
        var self=this;
        var dynamicSheet = global.app.sheet.create({
          content: `
            <div class="sheet-modal" style="height: 60%;">
              <div class="toolbar">
                <div class="toolbar-inner">
                  <div class="left"></div>
                  <div class="right">
                    <a class="link sheet-close">Done</a>
                  </div>
                </div>
              </div>
              <div class="sheet-modal-inner">
                <div class="block" id="ml_chart_div">
                </div>
              </div>
            </div>
          `,
          // Events
          on: {
            open: function (sheet) {
              console.log('Sheet open');
              var single_model=jQuery(self).attr('id').split('_')[0];

              var model_output={
                results:{
                  values:response.results.values[single_model]
                }
              }
              ai.drawAiChart('ml_chart_div', response,'p_inf')
            },
            opened: function (sheet) {
              console.log('Sheet opened');
            },
          }
        });
        dynamicSheet.open();
      });
    }
    else if (model_name=="multi_pest") {
      var optms='';
      var first_el='';

      jQuery.each(fd.aemodel,function(k,v){
        if (v.model_name=='multi_pest') {
          jQuery.each(v.inner_models,function(i,d){
            var selected='';
            if (i==0) {
              selected='selected';
              first_el=d.model_description;
            }
            optms+=`<option value="${d.model_name}" ${selected}>${d.model_description}</option>`;
          });
        }
      });

      var multi_select_div=`
      <div class="list">
        <ul>
          <li>
            <a class="item-link smart-select smart-select-init" data-open-in="popover" data-close-on-select="true">
              <select id="multi_pest">
                ${optms}
              </select>

              <div class="item-content">
                <div class="item-inner" style="width: fit-content;">
                  <div class="item-title"></div>
                  <div class="item-after" style="font-weight:bold;color:black;max-width: unset;margin-left:0;">${first_el}</div>
                </div>
              </div>
            </a>
          </li>
        </ul>
      </div>
      <div id="inner_model_result"></div>
      `
      jQuery("#model_result_multi_pest").html(multi_select_div)

      jQuery('#multi_pest').change(function(){
        jQuery('#section_result_chart_button').html('');


        jQuery('#inner_model_result').html('<div id="'+"model_result_"+jQuery('#multi_pest').val()+'"><img src="icons/loading.svg" style="width:40%;" id="loading_chat" /></div>');
        jQuery('.secondary_model').remove();

        if(jQuery('#multi_pest').val()=='aeco_grape_powdery'){
          jQuery('#inner_model_result').prepend('<div class="secondary_model"><h2>Rischio infezione</h2></div>');
          jQuery('#section_result').append('<div class="secondary_model"><hr><h2>Rischio presenza sintomi fra 7 giorni</h2><div id="model_result_aeco_grape_powdery_ml" style="text-align:center;"><img src="icons/loading.svg" style="width:40%;" id="loading_chat" /></div></div>');
          runModel(id_field, fd, 'aeco_grape_powdery_ml', false, jQuery('#multi_pest').val())
        }
        else if(jQuery('#multi_pest').val()=='aeco_grape_plasmo'){
          jQuery('#inner_model_result').prepend('<div class="secondary_model"><h2>Rischio infezione</h2></div>');
          jQuery('#section_result').append('<div class="secondary_model"><hr><h2>Rischio presenza sintomi fra 7 giorni</h2><div id="model_result_aeco_grape_plasmo_ml" style="text-align:center;"><img src="icons/loading.svg" style="width:40%;" id="loading_chat" /></div></div>');
          runModel(id_field, fd, 'aeco_grape_plasmo_ml',false, jQuery('#multi_pest').val())
        }

        runModel(id_field, fd, jQuery('#multi_pest').val())
        

      }).change();
      // jQuery("#model_result_multi_pest").html('<div id="'+"model_result_"+'aeco_corn_diabro'+'"></div>')
    }
    else{
      if (model_name!='aeco_corn_diabro' && model_name!='aeco_corn_ostrinia' && model_name!='aeco_corn_heli') {
        jQuery("#model_result_"+model_name).html(t("Modello non gestito!! Contattare lo sviluppatore"));
      }
    }

    if (response.final_value.alert || (response.final_value.data && response.final_value.data.alert)) {
      var obj;
      var ref_date;

      if (response.final_value.alert) {
        obj=response.final_value.alert;
        ref_date=response.final_value.datetime?response.final_value.datetime:response.final_value.date
      }
      else if (response.final_value.data.alert) {
        obj=response.final_value.data.alert;
        ref_date=response.final_value.data.datetime?response.final_value.data.datetime:response.final_value.data.date

      }

      if (model_name=='aeco_grape_ml') {
        ref_date=response.result_summary.gray.last_record.date;
      }
      if (jQuery("#model_result_"+model_name).html()=='Modello non gestito! Contattare lo sviluppatore') {
        jQuery("#model_result_"+model_name).html(`<div style="border-radius: 30px;padding: 10px;" class="alert-level alert_level_${obj.alert_level}"><h2>${t(model_name+'_'+obj.message)}</h2><h3>${t(model_name+'_'+obj.message+'_message')}</h3></div>`).append(`${t('Data di riferimento')}: <b><i>${t(ref_date)}</i></b>`);
      }
      else {
        jQuery("#model_result_"+model_name).parent().append(`<div style="margin-top:10px; border-radius: 30px;padding: 10px;" class="alert-level alert_level_${obj.alert_level}"><h2>${t(model_name+'_'+obj.message)}</h2><h3>${t(model_name+'_'+obj.message+'_message')}</h3></div>`).append(`<div id="ref_date">${t('Data di riferimento')}: <b><i>${t(ref_date)}</i></b></div>`);
        jQuery("#model_result_"+model_name).html('');
      }


      var ref_date_moment=new Date(ref_date).getTime();
      var today_moment=new Date().getTime();
      var diff_days=Math.round((today_moment-ref_date_moment)/(1000*60*60*24));
      datiMeteoAlert(diff_days, response, model_name);
    }
    else {
      var ref_date=fv.datetime?fv.datetime:fv.date;

      if (model_name=='aeco_grape_ml') {
        ref_date=response.result_summary.gray.last_record.date;
      }
      if (ref_date) {
        jQuery("#model_result_"+model_name).append(`${t('Data di riferimento')}: <b><i>${t(ref_date)}</i></b>`);
      }

      // check if ref_date is more than 5 days ago
      var ref_date_moment=new Date(ref_date).getTime();
      var today_moment=new Date().getTime();
      var diff_days=Math.round((today_moment-ref_date_moment)/(1000*60*60*24));
      datiMeteoAlert(diff_days, response, model_name);
    }

  }
  else{
    jQuery("#model_result_"+model_name).html(response.message);
  }
}

function datiMeteoAlert(diff_days, response, model_name){
  var ref_date=response.final_value.datetime?response.final_value.datetime:response.final_value.date;

  var alert_message='';
  if(ref_date){
    alert_message=` ${t('Attenzione! Il modello sta usando i dati meteo fino al '+ref_date+'.')}`;
  }

  if(response.final_value.model_end){
    alert_message=` ${t('Il ciclo produttivo della coltura è terminato il ')} ${t(ref_date)}.`;
  }

  if(!isNaN(diff_days) && diff_days>5){
    if(ref_date && ref_date!=''){
      jQuery("#model_result_"+model_name).parent().append(`
        <br>
        <span class="station_alert badge color-yellow" style="padding:3px; margin:10px; font-size:13px; display: inline-table; border-radius: 10px;">
          <a class="link external" style="color:white;">
           ${alert_message}
          </a>
        </span>
        <br>
      `);
    }
  }
}

function checkIfImageExists(url, callback) {
  const img = new Image();
  img.src = url;

  if (img.complete) {
    callback(true);
  } else {
    img.onload = () => {
      callback(true);
    };

    img.onerror = () => {
      callback(false);
    };
  }
}

