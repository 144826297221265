/*jshint esversion: 6 */

import global from "../global.js";
import storage from "../storage.js";
import * as tools from "../tools.js";
import t from '../translate.js';
import * as p_data from './partecipa/data.js';
import * as LZString from 'lz-string/libs/lz-string.js';
import writeImage from './write_image.js';

class photo {

  getPhotoDiv(guid_rilievo, existing_images){
    var self=this;
    var html='';
    var fileda4=1;

    var s=storage.get();
    var num_photo=0;

    try {
      if (typeof s.my_images=='undefined') {
        s.my_images={};
      }
      if (typeof s.my_images[guid_rilievo]=='undefined') {
        s.my_images[guid_rilievo]={};
      }

      s.my_images[guid_rilievo]=self.checkExistingImages(s, guid_rilievo, existing_images);

      num_photo=Object.keys(s.my_images[guid_rilievo]).length;
    }
    catch (e) {
      console.log(e);
    }

    fileda4=parseInt(parseInt(num_photo/4)+1);
    console.log(fileda4);
    for (var i = 0; i < fileda4; i++) {
      html+='<div class="row" style="margin-bottom:10px;">';

        html+='<div class="photo" id="photo_'+(1+(i*4))+'">';
          html+='<div class="take_photo">';
            html+='<i style="top: 33%;" class="icon f7-icons">photo</i>';
          html+='</div>';
        html+='</div>';

        html+='<div class="photo" id="photo_'+(2+(i*4))+'">';
          html+='<div class="take_photo">';
            html+='<i style="top: 33%;" class="icon f7-icons">photo</i>';
          html+='</div>';
        html+='</div>';

        html+='<div class="photo" id="photo_'+(3+(i*4))+'">';
          html+='<div class="take_photo">';
            html+='<i style="top: 33%;" class="icon f7-icons">photo</i>';
          html+='</div>';
        html+='</div>';

        html+='<div class="photo" id="photo_'+(4+(i*4))+'">';
          html+='<div class="take_photo">';
            html+='<i style="top: 33%;" class="icon f7-icons">photo</i>';
          html+='</div>';
        html+='</div>';

      html+='</div>';
    }

    return html;
  }


  checkExistingImages(s, guid_rilievo, existing_images){
    if(existing_images){
    jQuery.each(existing_images,function(k,v){

      var show_images=true;

      var images_link=global.base_link;
      if(global.images_link){
        images_link=global.images_link;
      }
      var imageUrl=images_link+v+".png";

      jQuery.each(s.my_images[guid_rilievo],function(kk,vv){
        if(vv.imageUrl){
          imageUrl=vv.imageUrl;
        }
      });

      var img={
        base64:"",
        imageUrl:imageUrl,
        guid:v,
      }

      jQuery.each(s.my_images[guid_rilievo],function(kk,vv){
        if (vv.imageUrl==img.imageUrl) {
          show_images=false;
          console.log("non mostro immagine perché già mostrata", img.imageUrl);
        }
      });
      if (show_images){
        console.log("mostro immagine che mancava", img.imageUrl);
        // look if the image is already saved 
        try{
          var image=s.my_images[guid_rilievo].filter(function(o){
            return o.guid==v;
          })[0];
          if (image) {
            img.saved=image.saved;
            if(!img.saved && image.imageUrl!=''){
              img.imageUrl=image.imageUrl;
            }
          }
        }
        catch(e){
          console.log(e);
        }

        s.my_images[guid_rilievo][v]=img;
      }
      // console.log("dopo", s.my_images[guid_rilievo]);

      
    });

  }
  return s.my_images[guid_rilievo];
}

addImages(obj){

  if(!obj.images){
    obj.images=[];
  }

  jQuery("#photo img").each(function(){
    let guid=jQuery(this).attr('data-guid-image');
    if (guid) {
      if(obj.images.indexOf(guid)==-1){
        obj.images.push(guid);
      }
    }
  });

  return obj;
  
}

  fillPhotoDiv(guid_rilievo, existing_images){


    if(typeof guid_rilievo=='undefined'){
      alert('Errore: guid_rilievo non definito');
      return false;
    }
    var self=this;
    // debugger
    var s=storage.get();
    var cont=0;
    var cont_getbase64=1;

    if (typeof s.my_images=='undefined') {
      s.my_images={};
    }
    if (typeof s.my_images[guid_rilievo]=='undefined') {
      s.my_images[guid_rilievo]={};
    }
    console.log("my_images", s.my_images[guid_rilievo]);
    console.log("existing_images", existing_images);

    // global.app.dialog.preloader('Sto processando le foto.');
    // debugger
    var pair={};
    
    // s.my_images[guid_rilievo]=jQuery.extend(s.my_images[guid_rilievo],existing_images);
    // s.my_images[guid_rilievo]

    s.my_images[guid_rilievo]=self.checkExistingImages(s, guid_rilievo, existing_images);


    jQuery.each(s.my_images[guid_rilievo],function(k,v){
      v.guid=k;
      console.log(v);
      cont++;
      console.log("cont ci passo",cont);
      pair[v.guid]=cont;

      // var time=500;
      // setTimeout( function(){

        // console.log("IMAGE----");
        console.log("image",v);
        console.log("imageUrl",v.imageUrl);
        var path=v.imageUrl;
        if (path=="") {
          // debugger
          if ( (global.iOS() || global.nomeApp=='Agro Abruzzo') && !v.base64.startsWith('data:image')) {
            if(global.nomeApp=='Agro Abruzzo'){
              v.base64=`data:image/png;base64,${v.base64}`
            }
          }
          path=v.base64;
          var html='';
          html+='<div>';
            html+=`<img data-guid-image="${v.guid}" width="60" height="80" src="${self.isImage(v.base64)?v.base64:'p_icons/file.png'}"></img>`;
          html+='</div>';
          console.log("HTML image:",html);
          jQuery('#photo_'+(cont)).html(html);

        }
        else {
          if (v.imageUrl.indexOf('file:///storage')==0 || v.imageUrl.indexOf('filesystem')==0) {
            p_data.getFileContentAsBase64(v.imageUrl,function(base64Image){
              console.log("xxxxxxxxxxxxxxx");
              var base64=base64Image;

              if (!self.isImage(base64)) {
                base64='p_icons/file.png';
              }

              console.log(cont,v.imageUrl);
              var html='';
              html+='<div>';
                html+='<img data-guid-image="'+v.guid+'" width="60" height="80" src="'+base64+'"></img>';
              html+='</div>';
              console.log(v.guid, pair[v.guid]);
              jQuery('#photo_'+(pair[v.guid])).html(html);
              // cont_getbase64++;
            });
          }
          else {
            var html='';
            html+='<div>';
              html+='<img data-guid-image="'+v.guid+'" width="60" height="80" src="'+path+'"></img>';
            html+='</div>';
            jQuery('#photo_'+(cont)).html(html);
          }

        }


      // }, time);
      // time += 500;
    });
    // global.app.dialog.close();

    var photos=[];
    jQuery.each(s.my_images[guid_rilievo],function(k,v){
      // if (!global.is_cordova()) {
      //   photos.push(v.base64);
      // }
      // else {
      // }
      if (v.imageUrl!='') {
        if (v.imageUrl.indexOf('filesystem')==0 || v.imageUrl.indexOf('file:///storage')==0) {
          p_data.getFileContentAsBase64(v.imageUrl,function(base64Image){
            var base64=base64Image;
            photos.push(base64);
          });
        }
        else {
          photos.push(v.imageUrl);
        }
      }
      else {
        photos.push(v.base64);
      }

    });

    setTimeout(function(){
      // console.log(photos);
      jQuery(".photo").unbind().click(function() {
        global.app.photoBrowser.destroy();

        var myPhotoBrowserStandalone = global.app.photoBrowser.create({
          photos: photos,
          pageBackLinkText: "Elimina",
          navbarOfText: "di",
          popupCloseLinkText:'Chiudi',
          on: {
            opened: function () {
              console.log('photo browser opened')
              // setTimeout(function(){
              // }, 1000);
                jQuery('.navbar-inner-centered-title.sliding').prepend(`<div class="left">
                  <a class="elimina_immagine">
                    <span>Elimina</span>
                  </a>
                </div>`);
                jQuery('.title').css('left','0px');

                jQuery('.elimina_immagine').click(function(){

                  var c=confirm('Sei sicuro di voler eliminare questa foto?');
                  if (!c) {
                    return false;
                  }

                  var index=myPhotoBrowserStandalone.activeIndex;
                  var guid=jQuery('.photo img').eq(index).attr('data-guid-image');
                  console.log(guid);
                  
                  var s=storage.get();
                  var my_images=s.my_images;
                  var images=my_images[guid_rilievo];
                  console.log(images);
                  console.log(guid);
                  delete images[guid];
                  console.log(images);
                  my_images[guid_rilievo]=images;
                  s.my_images=my_images;
                  storage.save(s);
                  
                  myPhotoBrowserStandalone.close();
                  
                  // svuota i div delle foto
                  jQuery('.photo').html('<div class="take_photo"><i style="top: 33%;" class="icon f7-icons">photo</i></div>');

                  self.fillPhotoDiv(guid_rilievo);  
                  


                });
            }
          }
        } 
      );



        if (photos.length>0) {
          var index=jQuery(this).attr('id').replace('photo_','');
          index=parseInt(index)-1;
          myPhotoBrowserStandalone.open(index);
        }
        // destroy photo browser when we're done with it
        myPhotoBrowserStandalone.on('close', function () {
          myPhotoBrowserStandalone.destroy();  
        });
      });
    },1500);


  }

  isImage(data){
    if( global.nomeApp=='Agro Abruzzo' || (data && data.startsWith('data:image'))){
      return true;
    }
    else{
      return false;
    }
  }

  takePicture(guid_rilievo, existing_images, sourceType){
    var self=this;
    if(global.is_cordova()){
      // take picture or select from gallery

      if(typeof sourceType=='undefined'){
        sourceType=navigator.camera.PictureSourceType.CAMERA;
      }

      var cameraOptions={
        quality: 75,
        saveToPhotoAlbum:true,
        correctOrientation:true,
        destinationType: navigator.camera.DestinationType.FILE_URI,
        sourceType: sourceType,
        mediaType: navigator.camera.MediaType.PICTURE,
        targetWidth:1280,
        targetHeight:1280
      };

      if (global.iOS() || global.nomeApp=='Agro Abruzzo') {
        if(global.nomeApp=='Agro Abruzzo'){
          cameraOptions.destinationType=navigator.camera.DestinationType.DATA_URL;
        }
      }
      navigator.camera.getPicture(
        function(imageUrl){

          if(global.iOS()){
            try{
              try{
                var oImage=JSON.parse(imageUrl);
                imageUrl=oImage.filename;
              }
              catch(e){
                console.log("imageerror",e);
              }            
            }
            catch(e){
              console.log(e);
            }
          }


          console.log("klean image in iOS",imageUrl);
          var base64 = "";
          if (global.iOS() || global.nomeApp=='Agro Abruzzo') {
            if(global.nomeApp=='Agro Abruzzo'){
              base64=imageUrl
              imageUrl='';
            }
          }

          self.save_image(base64,imageUrl,guid_rilievo);
          console.log(imageUrl);
        },
        function(message){
          alert(message);
        },
      cameraOptions);
    }
    else{
      if (!window.File || !window.FileReader || !window.FileList || !window.Blob) {
        alert('The File APIs are not fully supported in this browser.');
      }
      var input = document.getElementById('scatta_foto');
      // console.log(input);
      if (!input) {
         alert("Um, couldn't find the file input element.");
      }
      else if (!input.files) {
        alert("This browser doesn't seem to support the `files` property of file inputs.");
      }
      else if (!input.files[0]) {
        alert("Please select a file");
      }
      else {
        console.log("FOTOFINA");


        var img = document.createElement("img");

        var file = input.files[0];
        var fr = new FileReader();
        fr.onload = function(e){
          var base64=fr.result;

          var bassa_risoluzione=true;
          // debugger
          if (jQuery('#risoluzione_immagine').length>0) {
            if (jQuery('#risoluzione_immagine').val()=='alta') {
              bassa_risoluzione=false;
            }
          }

          if (global.iOS() || global.nomeApp=='Agro Abruzzo') {
            var guid=tools.getGuid();
            var settings=storage.get();

            // alert('iPhone non supporta ancora le foto');
            var s=storage.get();
            var cont=0
            // global.app.dialog.preloader('Sto caricando le foto.');
            if (!s.my_images) {
              s.my_images={};
            }

            s.my_images[guid_rilievo]=self.checkExistingImages(s, guid_rilievo, existing_images);


            jQuery.each(s.my_images[guid_rilievo],function(k,v){
              // v.guid=k;
              console.log(v);
              cont++;
            });

            // console.log(cont);
            jQuery('#photo_'+(cont+1)+' .take_photo').html('Sto caricando la foto...')
            // debugger
            var width = 0;
            var height = 0;

            // img.onload= function(){
            //   debugger
            //   width = img.width;
            //   height = img.height;
            // };
            img.onload = function() {
              width = img.naturalWidth;
              height = img.naturalHeight;
            };

            img.src = e.target.result;


            setTimeout(function() {
              var canvas = document.createElement("canvas");
              //var canvas = $("<canvas>", {"id":"testing"})[0];
              var ctx = canvas.getContext("2d");
              ctx.drawImage(img, 0, 0);

              var MAX_WIDTH = 900;
              var MAX_HEIGHT = 900;


              // debugger
              if (width > height) {
                if (width > MAX_WIDTH) {
                  height *= MAX_WIDTH / width;
                  width = MAX_WIDTH;
                }
              }
              else {
                if (height > MAX_HEIGHT) {
                  width *= MAX_HEIGHT / height;
                  height = MAX_HEIGHT;
                }
              }
              canvas.width = width;
              canvas.height = height;
              var ctx = canvas.getContext("2d");
              ctx.drawImage(img, 0, 0, width, height);

              var dataurl = canvas.toDataURL("image/png");
              // document.getElementById('output').src = dataurl;



              var base64_to_server=base64;
              if (bassa_risoluzione) {
                base64_to_server=dataurl;
              }

              var urladdr = global.base_path+"monitoring_webpack/store_images/insert/"+guid;
              var headers={};
              var request={
                  guid_survey:guid_rilievo,
                  note:'',
                  base64: base64_to_server,
                  access_token:settings.user.access_token,
                };
              if(global.plus){
                urladdr = global.base_call+"store_images/insert/"+guid;
                headers={
                  'Authorization': 'Bearer ' + s.user.access_token,
                  'Content-Type': 'application/json'
                };
                request=JSON.stringify(request);
              }
              jQuery.ajax({
                type: 'POST',
                // contentType: 'application/json',
                async:false,
                headers:headers,
                data:request,
                url: urladdr,
                dataType: "json",
                success: function(preverbale){
                  global.app.dialog.close();

                  console.log("img "+guid+" "+preverbale.ok+"<p/>");
                  if (preverbale.ok) {
                    var images_link=global.base_link;
                    if(global.images_link){
                      images_link=global.images_link;
                    }
                    var link=images_link+guid+".png";

                    


                    if (!self.isImage(base64)) {
                      link='p_icons/file.png';
                    }
                    var guid_image=guid;
                    self.save_image('',link,guid_rilievo, guid_image);
                  }
                  // updateImageStatus(guid, preverbale.ok);
                },
                error:function(e){
                  console.log(e);
                  // debugger
                  global.app.dialog.close();

                  if (!global.iOS()) {
                    writeImage(file.name, file,1);
                    self.save_image('','filesystem:'+window.location.origin+'/persistent/'+file.name,guid_rilievo);
                  }
                  else {
                    alert('Foto non caricata. Verificare di essere connesso alla rete.');
                  }
                }
              });
            }, 1000);

          }
          else {
            writeImage(file.name, file,1);
            self.save_image('','filesystem:'+window.location.origin+'/persistent/'+file.name,guid_rilievo);
          }

          // console.log('filesystem:http://localhost:8080/persistent/'+file.name);
          // console.log(fr);
        };
        fr.readAsDataURL(file);
     }
    }
  }

  save_image(base64,imageUrl,guid_rilievo, guid_image){


    
    var self=this;
    console.log("Base64",base64);
    console.log("imageurl",imageUrl);


    var s=storage.get();
    var my_images=s.my_images;

    if (typeof s.my_images=='undefined') {
      s.my_images={};
    }
    if (typeof s.my_images[guid_rilievo]=='undefined') {
      s.my_images[guid_rilievo]={};
    }

    if (my_images==null) {
      my_images={};
    }

    if (my_images[guid_rilievo]==null) {
      my_images[guid_rilievo]={};
    }

    var guid=tools.getGuid();
    my_images[guid_rilievo][guid]={'base64':base64,'imageUrl':imageUrl,'saved':false};

    var imageGuid=guid;
    if (guid_image) {
      imageGuid=guid_image;
    }
    // debugger
    var obs=null;
    if (s.observation) {
      if (guid_rilievo) {
        obs=s.observation.filter(function(o){
          return o.id_observation==guid_rilievo;
        })[0];
      }
      else {
        obs=s.observation;
      }
    }
    if(obs){
      if(!obs.images){
        obs.images=[];
      }
      obs.images.push(imageGuid);
    }
    else {
      obs={};
      obs.id_observation=guid_rilievo;
      obs.images=[];
      obs.images.push(imageGuid);
      // s.observation.push(obs);
    }
    
    // debugger
    if (imageUrl!='' && imageUrl.indexOf('file:///data/user')=='-1' && imageUrl.indexOf('file:///var/mobile')=='-1' && imageUrl.indexOf('file:///storage')=='-1' && imageUrl.indexOf('filesystem')=='-1') {
      my_images[guid_rilievo][guid].saved=true;
    }

    s.my_images=my_images;
    console.log(s);
    // debugger


    storage.save(s);


    setTimeout(function() {
      self.fillPhotoDiv(guid_rilievo);
    }, 200);

    // location.reload();
  }

}


export default (new photo());
